import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import MediaQuery from 'react-responsive';
import day from 'dayjs';
import { window } from 'browser-monads';
import { Carousel } from 'react-responsive-carousel';
import {
  SuperHeading,
  MediaListHeading,
  MediaListContent,
  CarouselImageWrapper,
  CarouselImage,
  Underline,
  MediaListDetail,
} from '../components/styled-components/elements';
import {
  MaxiMediaGrid,
  ParentGrid,
  GridItem,
  MiniMediaGrid,
  CentreWide,
  CentreAlign,
  MarginExcluder,
} from '../components/styled-components/structures';
import TemplateWrapper from '../layouts';

class IndexPage extends React.Component {
  state = {
    currentWidth: null,
    resizeListener: null,
  };

  widthRef = React.createRef();

  componentDidMount() {
    this.getBoundingClient();
    setTimeout(() => {
      this.getBoundingClient();
    }, 800);
    const resizeListener = window.addEventListener(
      'resize',
      this.getBoundingClient
    );
    this.setState({ resizeListener });
  }

  componentWillUnmount() {
    const { resizeListener } = this.state;
    window.removeEventListener('resize', resizeListener);
  }

  getBoundingClient = () => {
    if (
      this.widthRef &&
      this.widthRef.current &&
      this.widthRef.current.getBoundingClientRect
    ) {
      const { width } = this.widthRef.current.getBoundingClientRect();
      this.setState({ currentWidth: width });
    }
  };

  render() {
    const {
      data: {
        allFile,
        allMarkdownRemark: { edges },
      },
    } = this.props;
    const { currentWidth } = this.state;
    return (
      <TemplateWrapper>
        <MediaQuery maxWidth={650}>
          {(isSmall) => (
            <MediaQuery minWidth={900}>
              {(isBig) => (
                <ParentGrid gridColumns={isSmall ? 1 : 2} gridRows="20em">
                  <GridItem
                    gridArea={
                      isSmall
                        ? '1 / 1 / 2 / 2'
                        : isBig
                        ? '1 / 1 / 2 / 2'
                        : '1 / 1 / 2 / 3'
                    }
                  >
                    <CentreWide>
                      <MarginExcluder width={isBig ? 100 : 75}>
                        <div ref={this.widthRef}>
                          {this.widthRef.current && (
                            <Carousel
                              swipeable
                              showThumbs={false}
                              infiniteLoop
                              interval={5000}
                            >
                              {allFile.edges.map((edge) => {
                                const {
                                  src,
                                  aspectRatio,
                                } = edge.node.childImageSharp.fluid;
                                return (
                                  <CarouselImageWrapper
                                    key={edge.node.childImageSharp.fluid.src}
                                    className="carousel-image-wrapper"
                                  >
                                    <CarouselImage
                                      key={src}
                                      src={src}
                                      imgWidth={
                                        currentWidth / (1.4 / aspectRatio)
                                      }
                                      imgReduction={isSmall ? 0 : 50}
                                    />
                                  </CarouselImageWrapper>
                                );
                              })}
                            </Carousel>
                          )}
                        </div>
                      </MarginExcluder>
                    </CentreWide>
                  </GridItem>
                  <GridItem
                    gridArea={
                      isSmall
                        ? '3 / 1 / 4 / 2'
                        : isBig
                        ? '2 / 1 / 3 / 2'
                        : '2 / 1 / 3 / 2'
                    }
                  >
                    <Link to="/about">
                      <MaxiMediaGrid>
                        <CentreAlign>
                          <MediaListHeading>About the choirs!</MediaListHeading>
                        </CentreAlign>
                        <Underline />
                        <MediaListContent>
                          Find out about what we get up to.
                        </MediaListContent>
                      </MaxiMediaGrid>
                    </Link>
                    <Link to="/contact">
                      <MaxiMediaGrid>
                        <CentreAlign>
                          <MediaListHeading>Find out more?</MediaListHeading>
                        </CentreAlign>
                        <Underline />
                        <MediaListContent>
                          Send a message to find out more.
                        </MediaListContent>
                      </MaxiMediaGrid>
                    </Link>
                    <Link to="/what-we-sing">
                      <MaxiMediaGrid>
                        <CentreAlign>
                          <MediaListHeading>What do we sing?</MediaListHeading>
                        </CentreAlign>
                        <Underline />
                        <MediaListContent>
                          See a small section of our repertoire.
                        </MediaListContent>
                      </MaxiMediaGrid>
                    </Link>
                  </GridItem>
                  <GridItem
                    gridArea={
                      isSmall
                        ? '2 / 1 / 3 / 2'
                        : isBig
                        ? '1 / 2 / 3 / 3'
                        : '2 / 2 / 3 / 3'
                    }
                  >
                    <SuperHeading>Latest Stories</SuperHeading>
                    {edges.map(({ node: post }) => (
                      <Link key={post.id} to={post.fields.slug}>
                        <MiniMediaGrid>
                          <MediaListHeading>
                            {post.frontmatter.title}
                          </MediaListHeading>
                          <MediaListContent>
                            {post.excerpt.slice(0, 150)}
                            ...
                          </MediaListContent>
                          <MediaListDetail>
                            {day(post.frontmatter.date).format('DD MMM YYYY')}
                          </MediaListDetail>
                        </MiniMediaGrid>
                      </Link>
                    ))}
                  </GridItem>
                </ParentGrid>
              )}
            </MediaQuery>
          )}
        </MediaQuery>
      </TemplateWrapper>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allFile: PropTypes.object,
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "update" }, type: { eq: "public" } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    allFile(filter: { absolutePath: { regex: "/carousel/" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
